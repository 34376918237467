import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useCategory } from '@emico/category-graphql'
import { minWidth } from '@emico/styles'
import { Container, H2, H3 } from '@emico/ui'

import Breadcrumbs from '../../../components/Breadcrumbs'
import ButtonPrimary from '../../../components/ButtonPrimary'
import CreatePlateForm from '../../../components/CreatePlateForm'
import HtmlContent from '../../../components/HtmlContent'
import Meta from '../../../components/Meta'
import MetaCanonical from '../../../components/MetaCanonical'
import PageHeader from '../../../components/PageHeader'
import { SubmitCodeFormValues } from '../../../components/SubmitCodeForm'
import SubmitCodeForm from '../../../components/SubmitCodeForm'
import RoundCheckIcon from '../../../icons/RoundCheckIcon'
import getSeoPageDescription from '../../../lib/getSeoPageDescription'
import getSeoPageTitle from '../../../lib/getSeoPageTitle'
import theme from '../../../theme'
import { CraftRequestLicensePlateStaticData } from './useRequestLicensePlate'

const StyledContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  grid-column: 1 / -1;
`

const Content = styled.div`
  margin: ${theme.pagePadding} auto 0 auto;
  grid-column: 1 / -1;

  @media ${minWidth('lg')} {
    grid-column: 2 / 12;
  }
`

const StyledH2 = styled(H2)`
  font-size: ${theme.fontSizes['2xl']};
  text-transform: uppercase;
`

const StyledH3 = styled(H3)`
  margin-top: ${theme.spacing.lg};
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;

  @media ${minWidth('md')} {
    margin-top: ${theme.spacing.xl};
  }
`

const InfoTextWrapper = styled.div`
  margin-top: ${theme.spacing.md};
`

const CodeTextWrapper = styled.div`
  margin-top: ${theme.spacing.sm};
`

const CodeAppliedWrapper = styled.div`
  margin-top: ${theme.spacing.lg};
  padding: ${theme.spacing.md};
  border: 1px solid ${theme.colors.grayMiddle};

  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};

  @media ${minWidth('md')} {
    padding: ${theme.spacing.xl};
  }
`

const StyledRoundCheckIcon = styled(RoundCheckIcon)`
  font-size: 18px;
  color: ${theme.colors.green};
`

const CodeAppliedText = styled.p`
  margin: 0;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.grayDark};
  opacity: 0.7;
  text-transform: uppercase;
`

const SuccessText = styled.p`
  margin: 0;
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.light};
  text-transform: uppercase;
`

const ConfirmationTextWrapper = styled.div`
  margin-top: ${theme.spacing.md};
`

export const StyledButtonPrimary = styled(ButtonPrimary)`
  margin-top: ${theme.spacing.xl};
  width: 100%;

  @media ${minWidth('md')} {
    width: 50%;
  }
`

enum FormStep {
  ValidateCode,
  CreatePlate,
  Success,
}

type Props = CraftRequestLicensePlateStaticData

const RequesetLicensePlate = ({ craftDataRequestLicensePlate }: Props) => {
  const { data: goCartsCategory } = useCategory(4)
  const submitCodeForm = useForm<SubmitCodeFormValues>()
  const [formStep, setFormStep] = useState(FormStep.ValidateCode)
  const uniqueCodeRef = useRef<HTMLElement>()

  const codeValue = submitCodeForm.watch('code')

  const breadcrumbParents = goCartsCategory
    ? [
        {
          name: goCartsCategory.name,
          url: goCartsCategory.urlKey,
        },
      ]
    : []

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [formStep])

  const requestLicensePlateContent =
    craftDataRequestLicensePlate.__typename === 'CraftRequestLicensePlateEntry'
      ? craftDataRequestLicensePlate
      : undefined

  const headerImage = requestLicensePlateContent?.image?.[0]

  return (
    <>
      <Meta
        metaTitle={
          requestLicensePlateContent?.metaTitle ??
          getSeoPageTitle(t({ message: 'Request license plate' }))
        }
        metaDescription={
          requestLicensePlateContent?.metaDescription ?? getSeoPageDescription()
        }
      />

      <MetaCanonical />

      <section>
        {requestLicensePlateContent?.title && headerImage && (
          <PageHeader
            title={requestLicensePlateContent.title}
            subtitle={requestLicensePlateContent.subtitle ?? undefined}
            image={headerImage}
          />
        )}

        <StyledContainer>
          <StyledBreadcrumbs customParents={breadcrumbParents} />

          <Content>
            {formStep !== FormStep.Success ? (
              <>
                <StyledH2>{requestLicensePlateContent?.pageTitle}</StyledH2>

                {requestLicensePlateContent?.introduction ? (
                  <InfoTextWrapper>
                    <HtmlContent
                      html={requestLicensePlateContent.introduction}
                    />
                  </InfoTextWrapper>
                ) : null}

                <StyledH3 ref={uniqueCodeRef}>
                  <Trans>Enter unique code</Trans>
                </StyledH3>

                {requestLicensePlateContent?.requestCodeText ? (
                  <CodeTextWrapper>
                    <HtmlContent
                      html={requestLicensePlateContent.requestCodeText}
                    />
                  </CodeTextWrapper>
                ) : null}
              </>
            ) : null}

            {formStep === FormStep.ValidateCode ? (
              <SubmitCodeForm
                submitCodeForm={submitCodeForm}
                onIsValid={() => setFormStep(FormStep.CreatePlate)}
              />
            ) : null}

            {formStep === FormStep.CreatePlate ? (
              <>
                <CodeAppliedWrapper>
                  <StyledRoundCheckIcon />
                  <CodeAppliedText>
                    <Trans>Your code {codeValue} is applied</Trans>
                  </CodeAppliedText>
                </CodeAppliedWrapper>

                <CreatePlateForm
                  craftDataRequestLicensePlate={craftDataRequestLicensePlate}
                  uniqueCode={codeValue}
                  onPlateIsCreated={() => setFormStep(FormStep.Success)}
                />
              </>
            ) : null}

            {formStep === FormStep.Success ? (
              <>
                {requestLicensePlateContent?.successTitle ? (
                  <SuccessText>
                    <HtmlContent
                      html={requestLicensePlateContent.successTitle}
                    />
                  </SuccessText>
                ) : null}
                <StyledH2>
                  <Trans>We have received your request</Trans>
                </StyledH2>

                {requestLicensePlateContent?.successSubtitle ? (
                  <ConfirmationTextWrapper>
                    <HtmlContent
                      html={requestLicensePlateContent.successSubtitle}
                    />
                  </ConfirmationTextWrapper>
                ) : null}
                <StyledButtonPrimary
                  analyticsContext="request.license.plate"
                  analyticsName="create.plate"
                  colorType="neutral"
                  href="/"
                  ariaLabel={t({ message: 'Home' })}
                >
                  <Trans>Continue shopping</Trans>
                </StyledButtonPrimary>
              </>
            ) : null}
          </Content>
        </StyledContainer>
      </section>
    </>
  )
}

export default RequesetLicensePlate
