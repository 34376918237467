import { gql, TypedDocumentNode, ApolloClient } from '@apollo/client'

import { CraftImageFragment } from '../../../lib/craftFragments'
import { getCraftSiteProps } from '../../../lib/getCraftSiteProps'
import { ResolvedRoute } from '../../../lib/resolveRoute'
import {
  StoreLocatorQuery,
  StoreLocatorQueryVariables,
  ProductTypesQuery,
  ProductTypesQueryVariables,
  CraftStoreEntryFragment as CraftStoreEntryFr,
} from './useStoreLocator.generated'

export interface CraftStoreLocatorStaticData {
  craftDataStoreLocator: Exclude<StoreLocatorQuery['entry'], null>
  craftDataProductTypes: Exclude<ProductTypesQuery['entries'], null>
  resolvedRoute?: ResolvedRoute
  site: string
}

// TO-DO include all StoreLocator properties
export const STORE_LOCATOR_QUERY = gql`
  query StoreLocator($site: [String], $preferSites: [CraftQueryArgument]) {
    entry(
      type: "storeLocator"
      site: $site
      preferSites: $preferSites
      unique: true
    ) {
      ... on CraftStoreLocatorEntry {
        id
        uid
        metaTitle
        metaDescription
        title
        subtitle
        disclaimer
        disclaimerAssortment

        image {
          ...CraftImageFragment
          desktopUrl: url(width: 2500, height: 500)
          tabletUrl: url(width: 1982, height: 600)
          mobileUrl: url(width: 1534, height: 800)
        }
      }
    }
  }
  ${CraftImageFragment}
` as TypedDocumentNode<StoreLocatorQuery, StoreLocatorQueryVariables>

export const PRODUCT_TYPES_QUERY = gql`
  query ProductTypes($site: [String], $preferSites: [CraftQueryArgument]) {
    entries(
      type: "productType"
      site: $site
      preferSites: $preferSites
      unique: true
    ) {
      ... on CraftProductTypeEntry {
        id
        uid
        title
        isFilter: lightswitchUnchecked
      }
    }
  }
` as TypedDocumentNode<ProductTypesQuery, ProductTypesQueryVariables>

export const CraftStoreEntryFragment = gql`
  fragment CraftStoreEntryFragment on CraftStoreEntry {
    uri
    uid
    slug
    storeId
    title
    address1
    address2
    zipcode
    city
    latitude
    longitude
    phoneNumber: phoneNumberStatic
    email: emailStatic

    productTypes {
      uid
    }

    imageStoreCard {
      ...CraftImageFragment
      desktopUrl: url(width: 430, height: 185)
      mobileUrl: url(width: 530, height: 228)
    }
  }

  ${CraftImageFragment}
` as TypedDocumentNode<CraftStoreEntryFr>

export const getStaticProps = async (
  client: ApolloClient<unknown>,
  resolvedRoute: ResolvedRoute,
  locale?: string,
): Promise<CraftStoreLocatorStaticData> => {
  const sites = getCraftSiteProps(locale)
  const { data: storeLocatorData } = await client.query({
    query: STORE_LOCATOR_QUERY,
    variables: {
      ...sites,
    },
  })

  const { data: productTypesData } = await client.query({
    query: PRODUCT_TYPES_QUERY,
    variables: {
      ...sites,
    },
  })

  if (!storeLocatorData.entry) {
    throw new Error('Fetching craft store locator data failed')
  }

  if (!productTypesData.entries) {
    throw new Error('Fetching craft product types data failed')
  }

  return {
    craftDataStoreLocator: storeLocatorData?.entry ?? undefined,
    craftDataProductTypes: productTypesData?.entries ?? undefined,
    resolvedRoute,
    site: sites.preferSites?.[0] ?? sites.site?.[0] ?? 'b2c_nl',
  }
}
