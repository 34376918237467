import { globalWindow } from '@emico-utils/ssr-utils'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { MutableRefObject, useEffect } from 'react'

import getPaginationUrl from '../lib/getPaginationUrl'
import updateUrlWithPageKey from '../lib/updateUrlWithPageKey'
import { sizesLg as themeSizes } from '../theme/sizes'
import { spacingDefaults as themeSpacing } from '../theme/spacing'
import Pagination from './Pagination'
import PaginationButton from './PaginationButton'

const SCROLL_OFFSET = Math.round(
  parseInt(themeSizes.headerHeight) +
    parseInt(themeSizes.headerCollapsibleHeight) +
    parseInt(themeSpacing.lg),
)

interface RouterQuery {
  query: {
    page?: string
  }
}

interface Props {
  /**
   * Total number of pagination pages
   */
  pageCount: number
  /**
   * Number of the current page
   */
  currentPageIndex: number
  /**
   * Function to set current page inded
   */
  setCurrentPageIndex: (currentPageIndex: number) => void
  /**
   * Context for analytics
   */
  analyticsContext: string
  /**
   * Boolean check if pagination search is loading
   */
  isLoading?: boolean
  /**
   * Optional ref to auto scroll to top of pagination list after pagination interaction
   */
  scrollRef?: MutableRefObject<HTMLDivElement | null>
}

const PagePagination = ({
  pageCount,
  currentPageIndex,
  setCurrentPageIndex,
  analyticsContext,
  isLoading,
  scrollRef,
  ...other
}: Props) => {
  const router = useRouter()
  const { query }: RouterQuery = router
  const pageParam = query.page ? Number(query.page) : undefined

  const handlePaginationClick = (pageKey: number) => {
    updateUrlWithPageKey(router, pageKey, false)
  }

  const prevUrl = getPaginationUrl(router, currentPageIndex - 1)
  const nextUrl = getPaginationUrl(router, currentPageIndex + 1)

  /**
   * When URL pageParam changes update currentPageIndex to current pageParam
   */
  useEffect(() => {
    if (pageParam && pageParam !== currentPageIndex) {
      setCurrentPageIndex(pageParam)
    }
  }, [currentPageIndex, pageParam, setCurrentPageIndex])

  /**
   * Scroll to top of pagination list
   * when pagination / currentPageIndex changes
   */
  useEffect(() => {
    if (pageParam && scrollRef?.current && !isLoading) {
      globalWindow?.scrollTo({
        top: scrollRef.current.offsetTop - SCROLL_OFFSET,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        behavior: 'instant',
      })
    }
  }, [currentPageIndex, isLoading, pageParam, scrollRef, setCurrentPageIndex])

  return (
    <>
      <Head>
        {currentPageIndex > 1 && <link rel="prev" href={prevUrl} />}
        {currentPageIndex !== pageCount && <link rel="next" href={nextUrl} />}
      </Head>

      <Pagination
        current={currentPageIndex}
        total={pageCount}
        renderElement={({ current, title, isDivider, key }) => (
          <PaginationButton
            key={key}
            analyticsContext={analyticsContext}
            analyticsName={`to.${current}`}
            onPress={() => handlePaginationClick(current)}
            isDivider={isDivider}
            isActive={current === currentPageIndex}
          >
            {title}
          </PaginationButton>
        )}
        {...other}
      />
    </>
  )
}

export default PagePagination
