import styled from '@emotion/styled'
import React from 'react'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import Container from '../../../../components/Container'
import CraftPageBlocks from '../../../../components/CraftPageBlocks'
import Meta from '../../../../components/Meta'
import MetaCanonical from '../../../../components/MetaCanonical'
import PageHeader from '../../../../components/PageHeader'
import getSeoPageDescription from '../../../../lib/getSeoPageDescription'
import theme from '../../../../theme'
import { CraftBlogPostStaticData } from './useBlogPost'

const PageBlocksWrapper = styled.div`
  margin-top: ${theme.spacing['2xl']};
`

type Props = CraftBlogPostStaticData

const BlogPost = ({ craftDataBlogPost }: Props) => {
  const craftData =
    craftDataBlogPost?.__typename === 'CraftBlogPostEntry'
      ? craftDataBlogPost
      : undefined

  const headerImage = craftData?.image?.[0]

  return (
    <>
      {craftData?.metaTitle && (
        <Meta
          metaTitle={craftData?.metaTitle}
          metaDescription={
            craftData?.metaDescription ?? getSeoPageDescription()
          }
        />
      )}

      <MetaCanonical includeUrlParam />

      <section>
        {craftData?.title && headerImage && (
          <PageHeader
            title={craftData.title}
            date={craftData.postDate}
            image={headerImage}
          />
        )}

        <Container>
          <Breadcrumbs />
        </Container>

        {craftData && craftData?.pageBlocks.length !== 0 && (
          <PageBlocksWrapper>
            <CraftPageBlocks blocks={craftData.pageBlocks} />
          </PageBlocksWrapper>
        )}
      </section>
    </>
  )
}

export default BlogPost
