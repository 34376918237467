import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { NextRouter, useRouter } from 'next/router'
import React, { ReactNode, useRef, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { ButtonUnstyled } from '@emico/ui'

import { InformationItemsUnionType } from '../lib/customTypes'
import { opacityStates } from '../lib/transitionStates'
import theme from '../theme'
import Accordion, { AccordionHeader } from './Accordion'
import HtmlContent from './HtmlContent'
import NextLinkPrimary from './NextLinkPrimary'
import SectionHeader from './SectionHeader'

const SubjectItems = styled.div`
  .ReactCollapse--collapse {
    transition-property: ${theme.transition.properties.dimensions};
    transition-duration: ${theme.transition.durations.extraSlow};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }
`

const Items = styled.div`
  border-top: ${theme.borders.default};
`

const StyledAccordion = styled(Accordion)`
  border-bottom: ${theme.borders.default};

  ${AccordionHeader} {
    padding: ${theme.spacing.sm} 0;
  }
`

const GroupItemLabel = styled.span`
  font-weight: ${theme.fontWeights.bold};
  padding-right: ${theme.spacing.sm};
`

const ItemContent = styled.div`
  padding-bottom: ${theme.spacing.lg};
`

const LoadMoreItems = styled('div', {
  shouldForwardProp: (prop) => !['show'].includes(prop.toString()),
})<{ show: boolean }>`
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  opacity: ${({ show }) => (show ? 1 : 0)};
`

const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => !['state'].includes(prop.toString()),
})<{ state: TransitionStatus }>`
  margin-top: ${theme.spacing.md};
  width: 100%;
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  opacity: ${({ state }) => opacityStates[state]};
  font-weight: ${theme.fontWeights.medium};
  text-align: left;
`

const renderInformationItems = (
  items: InformationItemsUnionType,
  router: NextRouter,
) =>
  items?.map((item) => {
    const itemShort = item.informationItemShort?.[0]

    if (!itemShort?.itemTitle || !itemShort.richText) {
      return null
    }

    return (
      <StyledAccordion
        key={item.uid}
        label={<GroupItemLabel>{itemShort.itemTitle}</GroupItemLabel>}
        labelElement="h3"
        isCollapsedDefault={item.slug === router.query.itemSlug}
      >
        <ItemContent>
          <HtmlContent html={itemShort.richText} />

          {itemShort.showLink && (
            <NextLinkPrimary
              analyticsContext="information.items"
              analyticsName={itemShort.itemTitle}
              href={`${item.uri}`}
              isBasic
            >
              <Trans>More about this</Trans>
            </NextLinkPrimary>
          )}
        </ItemContent>
      </StyledAccordion>
    )
  })

interface Props {
  subjectItems: InformationItemsUnionType
  title?: ReactNode
  visibleItemsCount?: number
}

const InformationSubjectItems = ({
  title,
  subjectItems,
  visibleItemsCount = 10,
  ...other
}: Props) => {
  const router = useRouter()
  const [showAll, setShowAll] = useState<boolean>(false)
  const nodeRef = useRef(null)
  const initialItems = subjectItems?.slice(0, visibleItemsCount)
  const loadMoreItems = subjectItems?.slice(visibleItemsCount)
  const showLoadMore = subjectItems?.length > visibleItemsCount

  return (
    <SubjectItems {...other}>
      {title && <SectionHeader title={title} hasLargeTitle />}

      <Items>
        {renderInformationItems(initialItems, router)}

        {showLoadMore && (
          <>
            <Collapse isOpened={showAll}>
              <LoadMoreItems show={showAll}>
                {renderInformationItems(loadMoreItems, router)}
              </LoadMoreItems>
            </Collapse>

            <Transition nodeRef={nodeRef} in={!showAll} timeout={800}>
              {(state: TransitionStatus) => (
                <StyledButtonUnstyled
                  state={state}
                  analyticsContext="information.subject.items"
                  analyticsName="readMore"
                  onClick={() => setShowAll(true)}
                >
                  <Trans>Load more</Trans>...
                </StyledButtonUnstyled>
              )}
            </Transition>
          </>
        )}
      </Items>
    </SubjectItems>
  )
}

export default InformationSubjectItems
