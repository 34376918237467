import React from 'react'

import CraftPageBlocks from '../../../components/CraftPageBlocks'
import Meta from '../../../components/Meta'
import MetaCanonical from '../../../components/MetaCanonical'
import SchemaSearch from '../../../components/SchemaSearch'
import getSeoPageDescription from '../../../lib/getSeoPageDescription'
import { CraftPageStaticData } from './usePage'

type Props = CraftPageStaticData

const CraftPage = ({ craftDataPage }: Props) => {
  const isHome = craftDataPage?.__typename === 'CraftHomepageEntry'

  const craftData =
    craftDataPage?.__typename === 'CraftHomepageEntry' ||
    craftDataPage?.__typename === 'CraftDefaultEntry'
      ? craftDataPage
      : undefined

  if (!craftData?.pageBlocks) {
    return null
  }

  return (
    <>
      {craftData.metaTitle && (
        <Meta
          metaTitle={craftData.metaTitle}
          metaDescription={craftData.metaDescription ?? getSeoPageDescription()}
        />
      )}

      <MetaCanonical />

      {isHome && <SchemaSearch />}

      <CraftPageBlocks
        blocks={craftData.pageBlocks}
        themeColor={craftData.themeColorDropdown ?? undefined}
      />
    </>
  )
}

export default CraftPage
